.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.h-100vh {
  height: 100vh !important;
}
.sidebar {
  flex: 0 0 250px;
  max-width: 250px;
  min-width: 250px;
  width: 250px;
}
.ant-avatar > img {
  object-fit: fill !important;
}
.ant-tooltip-inner,
.site-description-item-profile-p-label,
.site-description-item-profile-wrapper {
  text-transform: capitalize;
}
.secondary-color {
  color: #ff7f00;
}
.app-header .app-header-wrapper.layout-top-nav {
  max-width: 100% !important;
}
.ant-switch.ant-switch-checked .ant-switch-inner,
.ant-switch .ant-switch-inner {
  padding-inline-start: 9px !important;
  padding-inline-end: 9px !important;
}
.ant-input-readonly,
.ant-input[readonly] {
  color: #d0d4d7;
  background-color: #f7f7f8 !important;
  border-color: #e6ebf1;
  box-shadow: none;
  /* cursor: not-allowed; */
  opacity: 1;
}
.addButton > .ant-btn > .ant-btn-icon + span {
  margin-left: 8px;
}
.ant-avatar-string {
  transform: scale(1) translateX(-50%) !important;
}
.ant-comment-content-author-name > :hover,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date-today
  .ant-picker-calendar-date-value,
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #ff7f00;
}
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #000;
}
.ant-pagination .ant-pagination-item-active a {
  color: #ff7f00;
}
.ant-tabs .ant-tabs-tab:hover,
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  color: #ff7f00;
}
.ant-tabs .ant-tabs-ink-bar {
  background: #ff7f00;
}
.ant-input:hover,
:where(.css-dev-only-do-not-override-13qitfp).ant-select-outlined:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  .ant-select-selector,
.ant-select-selector:active,
.ant-select-selector:focus {
  border-color: #ff7f00;
}
.ant-drawer-content-wrapper-hidden {
  width: 0px !important;
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none !important;
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
  visibility: visible !important;
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  animation: none !important;
}
.ant-drawer-open {
  transition: none !important;
}
.ant-drawer-motion-leave {
  transition: none !important;
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + -5px);
}
.cursor-poniter {
  cursor: pointer !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-btn-primary {
  background-color: #ff7f00; /* Primary color */
  border-color: #ff7f00; /* Border color */
  color: #ffffff; /* Text color */
}

.ant-btn-primary:hover {
  background-color: #e67400; /* Hover background color */
  border-color: #e67400; /* Hover border color */
  color: #ffffff; /* Text color on hover */
}

.ant-btn-primary:active {
  background-color: #cc6600; /* Active background color */
  border-color: #cc6600; /* Active border color */
  color: #ffffff; /* Text color when active */
}
/* .ant-menu-item-selected:hover {
  color: #000000;
  .anticon {
    color: #000;
  }
  .ant-menu-title-content {
    color: #000;
  }
} */
/* 
.ant-pagination {
  .ant-pagination-item {
    border-color: #ff7f00;
    color: #ff7f00;
  }

  .ant-pagination-item a {
    color: #ff7f00;
  }

  .ant-pagination-item-active {
    background-color: #ff7f00;
    border-color: #ff7f00;
    color: #ffffff;
  }

  .ant-pagination-item-active a {
    color: #ffffff;
  }
  .ant-pagination-item-active:hover a {
    color: #ffffff !important;
  }
  .ant-pagination-item:hover a {
    color: #ff7f00;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    color: #ff7f00;
    border-color: #ff7f00;
  }

  .ant-pagination-prev a,
  .ant-pagination-next a {
    color: #ff7f00;
  }

  .ant-pagination-prev:hover,
  .ant-pagination-next:hover {
    color: #ff7f00;
    border-color: #ff7f00 !important;
    .ant-pagination-item-link {
      border: 0px !important;
    }
  }

  .ant-pagination-prev:hover a,
  .ant-pagination-next:hover a {
    color: #ff7f00;
  }

  .ant-pagination-prev-disabled,
  .ant-pagination-next-disabled {
    color: #cccccc;
    border-color: #cccccc;
  }

  .ant-pagination-prev-disabled a,
  .ant-pagination-next-disabled a {
    color: #cccccc;
  }

  .ant-pagination-prev .anticon,
  .ant-pagination-next .anticon {
    color: #ff7f00;
  }

  .ant-pagination-prev:hover .anticon,
  .ant-pagination-next:hover .anticon {
    color: #ff7f00;
  }

  .ant-pagination-prev-disabled .anticon,
  .ant-pagination-next-disabled .anticon {
    color: #cccccc;
  }
} */

::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #ff7f00; /* Color of the scrollbar */
  border-radius: 10px; /* Rounded scrollbar */
  border: 3px solid #f1f1f1; /* Add some space between the scrollbar and track */
}

.gallery-image:hover {
  transform: scale(1.05);
}

/* Gallery.css */
.masonry-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
}

.masonry-grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 8px;
  overflow: hidden;
}

.gallery-image-masonry {
  width: 100%;
  height: auto;
  display: block;
}

